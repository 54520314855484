import '../App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import discord from '../assets/discord.png';
import twitter from '../assets/twitter.png';
import nft from '../assets/nft.png'
import $ from 'jquery';
import pin from '../assets/tack.png';
import pin2 from '../assets/tack3.png';
import circle from '../assets/tack.png';
import map from '../assets/map-silkroad.png';
import stickyHand from '../assets/sticky.png';
import paper from '../assets/paper.png';
import paper1 from '../assets/paper2.png';
import paper2 from '../assets/paper3.png';
import paper3 from '../assets/paper4.png';
import paper4 from '../assets/paper5.png';
import paperNew from '../assets/paper6.png';
import audio from '../audio/thanos.mp3';
import bloodBtn from '../assets/bloodBtn.png'
import video from '../assets/1.mkv';
import video2 from '../assets/2.mkv';
import video3 from '../assets/3.mkv';
import secretGif from '../assets/secret_images.gif';
import Countdown from 'react-countdown';
import bid from '../assets/bid.png';
import login from '../assets/login.png';

var Scroll = require('react-scroll');


/*................ Javascript .................*/


/*................ Javascript .................*/

/*$(document).ready(function () {
	$('#button').onClick(function(){
		$('#target_element').addClass('disappear').show();
	});
});*/

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

const opensea = () => {
	window.open("https://opensea.io/collection/boys-club-official-nft");
}

const afterClick = () => {

	$(".carriage").addClass("afterClick");


}

const afterClick1 = () => {

	$(".carriage1").addClass("afterClick");


}


const afterClick2 = () => {

	$(".carriage2").addClass("afterClick");


}


const afterClick3 = () => {

	$(".carriage3").addClass("afterClick");


}


const afterClick4 = () => {

	$(".carriage4").addClass("afterClick");


}


const afterClick5 = () => {

	$(".carriage5").addClass("afterClick");


}


const afterClick6 = () => {

	$(".carriage6").addClass("afterClick");


}

const afterClick7 = () => {

	$(".carriage7").addClass("afterClick");


}



let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let mintStatus = ''
let cost = '';
let ferrisWheel;
let wrongMsgs = [
	"Pat ordered a ghost pepper pie.",
	"This made him feel like an old-style rootbeer float smells.",
	"Happiness can be found in the depths of chocolate pudding.",
	"He was sitting in a trash can with high street class.",
	"The tears of a clown make my lipstick run, but my shower cap is still intact.",
	"I think I will buy the red car, or I will lease the blue one.",
	"Nancy was proud that she ran a tight shipwreck.",
	"He shaved the peach to prove a point.",
	"The stench from the feedlot permeated the car despite having the air conditioning on recycled air.",
	"It didn't make sense unless you had the power to eat colors.",
	"I became paranoid that the school of jellyfish was spying on me.",
	"It was difficult for Mary to admit that most of her workout consisted of exercising poor judgment.",
	"It was the best sandcastle he had ever seen.",
	"It's never comforting to know that your fate depends on something as unpredictable as the popping of corn.",
	"Art doesn't have to be intentional.",
	"He didn't heed the warning and it had turned out surprisingly well.",
	"I want to buy a onesie… but know it won’t suit me.",
	"His son quipped that power bars were nothing more than adult candy bars.",
	"He realized there had been several deaths on this road, but his concern rose when he saw the exact number.",
	"It isn't true that my mattress is made of cotton candy.",
	"Now I need to ponder my existence and ask myself if I'm truly real",
	"It was always dangerous to drive with him since he insisted the safety cones were a slalom course.",
	"Even with the snow falling outside, she felt it appropriate to wear her bikini.",
	"He liked to play with words in the bathtub.",
	"The heat You've been eyeing me all day and waiting for your move like a lion stalking a gazelle in a savannah.",
	"For the 216th time, he said he would quit drinking soda after this last Coke.",
	"She discovered van life is difficult with 2 cats and a dog.",
	"The sign said there was road work ahead so he decided to speed up.",
	"With a single flip of the coin, his life changed forever.",
	"Grape jelly was leaking out the hole in the roof.",
	"We should play with legos at camp.",
	"It doesn't sound like that will ever be on my travel list.",
	"Improve your goldfish's physical fitness by getting him a bicycle.",
	"The llama couldn't resist trying the lemonade.",
	"The toddler’s endless tantrum caused the entire plane anxiety.",
	"It was obvious she was hot, sweaty, and tired.",
	"The hawk didn’t understand why the ground squirrels didn’t want to be his friend.",
	"If I don’t like something, I’ll stay away from it.",
	"Carol drank the blood as if she were a vampire.",
	"I'm confused: when people ask me what's up, and I point, they groan.",
	"The two walked down the slot canyon oblivious to the sound of thunder in the distance.",
	"He said he was not there yesterday; however, many people saw him there.",
	"The beauty of the African sunset disguised the danger lurking nearby.",
	"She was disgusted he couldn’t tell the difference between lemonade and limeade.",
	"This book is sure to liquefy your brain.",
	"He dreamed of eating green apples with worms.",
	"Honestly, I didn't care much for the first season, so I didn't bother with the second.",
	"The glacier came alive as the climbers hiked closer.",
	"Two more days and all his problems would be solved.",
	"The miniature pet elephant became the envy of the neighborhood.",
	"Having no hair made him look even hairier."
];

let correctMsgs = [
	"You can count. Don't be so proud. Still, tell the Cartoonist. He'd be interested.",
	"Huh. Didn't expect you to figure that out. But anyways, do you read?",
	"It's always the last breath that draws the most meaning. Published by New York and Boston H. M. Caldwell Company Publishers.",
	"Long one wasn't it? That one took you years to write. Almost like you gave birth. But you didn't, someone else did. #=years",
	"I love bold, italics, and underline. But my FIRST option would be italics.",
	"Wow. You're good. Go tell the Cartoonist the last thing you wrote. You are a star."
];
let e;
let soundfinder;
let song;
let myTokenIDs = [];
let myPuzzleIDs = [];
let elementArray = [];
let biddingMiddlePiece;
let winningValue = 0;
let biddingClosingTimeByOwner = 0;
let bidDepositer;
let bidDepositedList = [];
let bidAmountsList = [];
let bidFullList = [];
let biddingInfo = 0;
let biddingNote = 0;
let successfullyRegistered = 0;
let gap = 0;
let nextValue = 0;
let costBidding = "";
let fullPuzzles = [];
let puzzlePieceIDs = "";
let pieceID = [];
let middlePieceIDUrl;
let ownerOfTheMiddlePieceOwner;
let pieceID2 = [];
let roundCount = 0;
let mintingAllowed2 = true;
let finalMintingPieces = [];
let bidCount;
let winnerNickname;
let nickNameWithWallet;
let nickNameWithWalletCall;
let nickNameList = [];
let filteredMiddleList = [];

var count = 1;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [
	"0x617A4B4f35122293d9d0EA924b17055D4E550c44",
	"0xb00267C61b5d1BC380F1Afa106957697727cC519",
	"0xdc949797bc04d72b2a547d6bd796a51eb15d0a88",
	"0x85aa1db44F588209306fA6A48e4F2ACb0b20189B",
	"0x529e97d40eA063560d16b2a7B14610ea75F16EDf",
	"0x89ed12f746018fdc6ce04c1030dc246e8ba7cc3b",
	"0x6E3EDb79924803e6044704A970406DD3632cAaf9",
	"0x8847239E8d14EF122CFDeC7D0314aD27C3cD0D6b",
	"0xB51367929e153CadC8856695C165CeA08324C349",
	"0x9F322eA2F4Dd8Bbc1e02a7879E74Fb01914192c6",
	"0x8f7bA266AecEBbD2C7D98576cA638949d08BecD3",
	"0x650d492E2F68A665a5dc029bc3f255FE5ec366Fc",
	"0xEDaCA2961edF7592F6d05f5bCB6E0F9BE3787Def",
	"0x103a058412df3A36e5fb7511E637d3e53D91EEea",
	"0x1c28f041e4d70fecb05482a669f019377f1e20bc",
	"0x457a78141F8bEC38037E3886c5160dEC18637c14",
	"0x835B4485955ba8046231275318b579a1165cee2d",
	"0x12E862e0792d22C1B16Ca6F1df878f4Fc006Cf61",
	"0x002F874F745Ecb5b3D7FAe5196B3b037650Cf11d",
	"0x5be840577d46c18971446736578B5d12F60E604F",
	"0x5B22579045aa510d979766A47E58C78453EF13EC",
	"0x636Fb08404366EB0cFB8bda4a23e8B5BdD12ac80",
	"0x52bc7312B2AED57D30dddE7Bf8a3AD05BD1232F8",
	"0x3924bC70C63aA1c4bE7e94004DC869Fa633D3091",
	"0x2BDE2d294D7C775BA37E7B18be40D635A71E8349",
	"0xB4f22A8f58d1Fc1D098254d5C512E725Be85ca03"
];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "accessRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyWithdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fundReleasingPeriod",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "lastWithdrawnTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "mintedRefundID",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "projectStartDate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refundActivity",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "refundTokenIDWithOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newAccessRate",
				"type": "uint256"
			}
		],
		"name": "setAccessRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fundReleasingPeriod",
				"type": "uint256"
			}
		],
		"name": "setFundReleasingPeriod",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_timeLimit",
				"type": "uint256"
			}
		],
		"name": "setTimeLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_ps_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_ps_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_wl_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_wl_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newWhitelistCost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_newWhitelistSigner",
				"type": "bytes32"
			}
		],
		"name": "setWhitelistSigner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "timeLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleRefundActivity",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_wl_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_ps_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_ps_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32[]",
				"name": "_proof",
				"type": "bytes32[]"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSigner",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x581B88FC7a8659988C3258C924eCC08652511D83";

const biddingABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "_nft_Contract",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_puzzleIndex",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_pieceIDs",
				"type": "uint256[]"
			}
		],
		"name": "PuzzleInput",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "additionalBidValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id1",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id9_by_seller",
				"type": "uint256"
			}
		],
		"name": "bid",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "bidCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "bidDeposit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "bidDepositer",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "bidGap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "bidList",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "biddingClosingTimeByOwner",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deleteData",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "depositedAlready",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "depositedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "initialBiddingTimeGap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "listCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "middlePieceID",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mutex",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nextMinBidValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "nickNameWithWallet",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzleIndexfinder",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzlePieceIDs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_additionalBidValue",
				"type": "uint256"
			}
		],
		"name": "setAdditionalBidValue",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_bidGap",
				"type": "uint256"
			}
		],
		"name": "setBidGap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_initialBiddingTimeGap",
				"type": "uint256"
			}
		],
		"name": "setInitialBiddingTimeGap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nftContract",
				"type": "address"
			}
		],
		"name": "setNFTContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_nickname",
				"type": "bytes"
			}
		],
		"name": "setNickNameWithWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_public_mint_status",
				"type": "bool"
			}
		],
		"name": "setPublic_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_biddingClosingTimeByOwner",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "puzzleID",
				"type": "uint256"
			}
		],
		"name": "setbiddingClosingTimeByOwner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "solvedPuzzles",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winnerNickname",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningBid",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningDepositer",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

const biddingAddress = "0x96FD97FC44d89AA540b7Cd896675d2C68b34ba20";

const ABI3 = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "_nft_bidding_Contract",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_nft_piece_Contract",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_puzzleIndex",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_pieceIDs",
				"type": "uint256[]"
			}
		],
		"name": "PuzzleInput",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id1",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id2",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id3",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id4",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id5",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id6",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id7",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id8",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_id9",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft2",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzleIndexfinder",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "puzzlePieceIDs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nft_bidding_Contract1",
				"type": "address"
			}
		],
		"name": "setNFTContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nft_piece_Contract2",
				"type": "address"
			}
		],
		"name": "setNFTContract2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_public_mint_status",
				"type": "bool"
			}
		],
		"name": "setPublic_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "solvedPuzzles",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

const address3 = "0xdD856B186faB9a213e2fCB23c06Dd279A947E07e";

let contract;
let contractBidding;
let contract3;
let nextMinBidValue = 0;

const curtainOpen = () => {

	$(".leftCurtain").animate({
		left: '30%'
	},

		1000,

		function () {
			$(this).addClass("leftCurtainAnimation");

			/*	setTimeout(curtainRemoval, 2000);
				function curtainRemoval() {
					$(".leftCurtain").hide();
					$(".leftCurtainAnimation").hide();
	
				}*/
		}
	);

	$(".rightCurtain").animate({
		right: '30%'
	},

		1000,

		function () {
			$(this).addClass("rightCurtainAnimation");


			/*	setTimeout(curtainRemoval, 2000);
				function curtainRemoval() {
					$(".rightCurtain").hide();
					$(".rightCurtainAnimation").hide();
	
				}*/
		}
	);

	setTimeout(removal, 500);
	function removal() {
		$(".enterBtn").hide();

	}

	$(".spaceCover").animate({
		right: '30%'
	},

		1000,

		function () {
			$(this).hide();
		}
	);
}

class Home extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_mintStatus: '',
		_cost: '',
		_tsr: 0,
		_faq: 0,
		_provenance: 0,
		_about: 0,
		_lelouvre: 0,
		_bidding: 0,
		_pic1: 0,
		_pic2: 0,
		_pic3: 0,
		_pic4: 0,
		_pic5: 0,
		_pic6: 0,
		_pic7: 0,
		_pic8: 0,
		_ferrisWheel: 1,
		_bloodBtn: 0,
		_code: "",
		_wrongMsgs: [],
		_correctMsgs: [],
		_msg: "",
		_msgCode: "",
		sound: 0,
		_myTokenIDs: [],
		_myPuzzleIDs: [],
		_elementArray: [],
		_selectedPuzzleID: "",
		_biddingArena: 0,
		_biddingAmount: 0,
		_nextMinBidValue: 0,
		_winningValue: 0,
		_biddingClosingTimeByOwner: 0,
		stateNow: Date.now(),
		_biddingMiddlePiece: "",
		_bidDepositer: "",
		_bidCount: "",
		_bidFullList: [],
		_biddingInfo: 0,
		_biddingNote: 0,
		_gap: 0,
		_nextValue: 0,
		_winner: '',
		_mint: 0,
		_minting: 0,
		_winnerMint: '',
		_mintDialogue: 0,
		_costBidding: '',
		_fullPuzzles: [],
		_puzzlePieceIDs: '',
		_pieceID: [],
		_collectScreen: 0,
		_middlePieceID: '',
		_piece1: '',
		_piece2: '',
		_piece3: '',
		_piece4: '',
		_piece5: '',
		_piece6: '',
		_piece7: '',
		_piece8: '',
		_piece9: '',
		_finalMintingPieces: [],
		_mintingAllowed2: true,
		_bidCount: '',
		_winnerNickname: '',
		_nickNameWithWallet: '',
		_nickNameWithWalletCall: '',
		_nickNameList: [],
		_filteredMiddleList: [],
		_successfullyRegistered: 0

	}

	async componentDidMount() {
		curtainOpen();
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 800 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 800) {

				onlyLeft = 10 - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 800 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 800) {

				onlyLeft = 10 - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(/*mintAmount * 1*/).send({gasLimit: 685000, from: account, value: 1*10**18 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(/*mintAmount * 1*/).send({ gasLimit: 685000, from: account, value: 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	wallet2Connect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		/*provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});*/

		// Subscribe to chainId change
		/*provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});*/

		// Subscribe to provider connection
		/*provider.on("connect", (info) => {
			console.log(info);
		});*/

		// Subscribe to provider disconnection
		/*provider.on("disconnect", (error) => {
			console.log(error);
		});*/

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			contractBidding = new web3.eth.Contract(biddingABI, biddingAddress);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {


						const chainId = 5;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}





						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							costBidding = await contractBidding.methods.publicSaleCost().call();
							this.setState({ _costBidding: costBidding });
							console.log("costBidding :" + costBidding);

							let nickNameWithWalletCallbytes = await contractBidding.methods.nickNameWithWallet(account).call();


							let hex_string = nickNameWithWalletCallbytes.slice(2);

							nickNameWithWalletCall = Buffer.from(hex_string, 'hex').toString('utf8');
							console.log("nickNameWithWalletCall :" + nickNameWithWalletCall); // will output 'Test_1'

							this.setState({ _nickNameWithWalletCall: nickNameWithWalletCall });




							/*contract.methods.getString().call()
								.then((bytes) => {
									const string = Buffer.from(bytes).toString('utf8');
									console.log(string);
								})
								.catch(console.error);*/

							for (let y = 0; y < 100; y++) {
								fullPuzzles[y] = y + 1;
								this.setState({ _fullPuzzles: fullPuzzles });
							}

							for (let y = 0; y < this.state.myNFTWallet; y++) {

								myTokenIDs[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								myPuzzleIDs[y] = await contractBidding.methods.puzzleIndexfinder(myTokenIDs[y]).call();
								await new Promise(resolve => setTimeout(resolve, 50));

								this.setState({ _myTokenIDs: myTokenIDs });
								this.setState({ _myPuzzleIDs: myPuzzleIDs });


								myPuzzleIDs.forEach((c) => {
									if (!filteredMiddleList.includes(c)) {
										filteredMiddleList.push(c);
									}
								});

								this.setState({ _filteredMiddleList: filteredMiddleList });



								elementArray[y] = [myTokenIDs[y], myPuzzleIDs[y]];
								await new Promise(resolve => setTimeout(resolve, 50));
								this.setState({ _elementArray: elementArray });
								console.log("ElementArray" + this.state._elementArray);

								await new Promise(resolve => setTimeout(resolve, 50));
								this.setState({ _myPuzzleIDs: myPuzzleIDs });
								console.log("myPuzzleIDs" + this.state._myPuzzleIDs);

							}





							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 800 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 800) {

									onlyLeft = 800 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 5;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}




						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							costBidding = await contractBidding.methods.publicSaleCost().call();
							this.setState({ _costBidding: costBidding });
							console.log("costBidding :" + costBidding);

							
							let nickNameWithWalletCallbytes = await contractBidding.methods.nickNameWithWallet(account).call();


							let hex_string = nickNameWithWalletCallbytes.slice(2);

							nickNameWithWalletCall = Buffer.from(hex_string, 'hex').toString('utf8');
							console.log("nickNameWithWalletCall :" + nickNameWithWalletCall); // will output 'Test_1'

							this.setState({ _nickNameWithWalletCall: nickNameWithWalletCall });

							for (let y = 0; y < 100; y++) {
								fullPuzzles[y] = y + 1;
								this.setState({ _fullPuzzles: fullPuzzles });
							}

							for (let y = 0; y < this.state.myNFTWallet; y++) {

								myTokenIDs[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								myPuzzleIDs[y] = await contractBidding.methods.puzzleIndexfinder(myTokenIDs[y]).call();
								await new Promise(resolve => setTimeout(resolve, 50));

								this.setState({ _myTokenIDs: myTokenIDs });
								this.setState({ _myPuzzleIDs: myPuzzleIDs });


								myPuzzleIDs.forEach((c) => {
									if (!filteredMiddleList.includes(c)) {
										filteredMiddleList.push(c);
									}
								});

								this.setState({ _filteredMiddleList: filteredMiddleList });



								elementArray[y] = [myTokenIDs[y], myPuzzleIDs[y]];
								await new Promise(resolve => setTimeout(resolve, 50));
								this.setState({ _elementArray: elementArray });
								console.log("ElementArray" + this.state._elementArray);

								await new Promise(resolve => setTimeout(resolve, 50));
								this.setState({ _myPuzzleIDs: myPuzzleIDs });
								console.log("myPuzzleIDs" + this.state._myPuzzleIDs);

							}



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 800 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 800) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	wallet3Connect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			contractBidding = new web3.eth.Contract(biddingABI, biddingAddress);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {


						const chainId = 5;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}





						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();

								myPuzzleIDs[y] = await contractBidding.methods.puzzleIndexfinder(myTokenIDs[y]).call();

								this.setState({ _myTokenIDs: myTokenIDs });
								this.setState({ _myPuzzleIDs: myPuzzleIDs });

							}

							elementArray = [myTokenIDs, myPuzzleIDs];
							this.setState({ _elementArray: elementArray });

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 800 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 800) {

									onlyLeft = 800 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {



						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}




						try {

							mintStatus = await contract.methods.paused().call();
							this.setState({ _mintStatus: mintStatus });
							console.log("mintStatus2 :" + mintStatus);


							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();

								myPuzzleIDs[y] = await contractBidding.methods.puzzleIndexfinder(myTokenIDs[y]).call();

								this.setState({ _myTokenIDs: myTokenIDs });
								this.setState({ _myPuzzleIDs: myPuzzleIDs });

							}

							elementArray = [myTokenIDs, myPuzzleIDs];
							this.setState({ _elementArray: elementArray });

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 800 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 800) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 10 - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	whitelistMint = async event => {
		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });

		if (FinalResult) {
			try {
				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.whitelistMint(hexProof, wMintAmount).send({ gasLimit: 285000, from: account, value: wMintAmount * 12000000000000000 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.wMintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);
			}
		} else {
			wlMsg = "Not Eligible for WL"
			this.setState({ _wlMsg: wlMsg });
		}

	}

	tsr = async event => {
		event.preventDefault();

		this.setState({ _tsr: 1 });
		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 0 });

	}

	provenance = async event => {
		event.preventDefault();

		this.setState({ _tsr: 0 });
		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 1 });

	}


	faq = async event => {
		event.preventDefault();

		this.setState({ _faq: 1 });
		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 0 });

	}

	about = async event => {
		event.preventDefault();

		this.setState({ _about: 1 });
		this.setState({ _mint: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 0 });

	}

	lelouvre = async event => {
		event.preventDefault();

		this.setState({ _lelouvre: 1 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 0 });


	}

	async mintPieceM(tokenID_Middle) {
		this.setState({ _minting: 1 });

		try {

			console.log("tokenID_Middle : " + tokenID_Middle);
			let winnerMint = await contractBidding.methods.winner(tokenID_Middle).call();
			this.setState({ _winnerMint: winnerMint });
			console.log("winnerMint : " + winnerMint);
			console.log("account : " + account);

			let biddingClosingTimeByOwnerM = await contractBidding.methods.biddingClosingTimeByOwner(tokenID_Middle).call();

			if (winnerMint == account && Date.now() > (biddingClosingTimeByOwnerM * 1000)) {

				this.setState({ statusError: false, statusLoading: true });
				await contractBidding.methods.mint(tokenID_Middle).send({ gasLimit: 685000, from: account, value: this.state._costBidding * 1 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);

			} else {
				this.setState({ _mintDialogue: 1 });
			}

		} catch (err) {
			this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
			console.log(err);
		}

	}

	async collectScreen(fullPuzzleID) {

		try {
			this.setState({ _collectScreen: 1 });

			this.setState({ _middlePieceID: fullPuzzleID });

			await new Promise(resolve => setTimeout(resolve, 500));

			try {

				ownerOfTheMiddlePieceOwner = await contractBidding.methods.ownerOf(fullPuzzleID).call();
			} catch (err) {
				console.log(err);
			}

			if (ownerOfTheMiddlePieceOwner == account) {

				middlePieceIDUrl = "https://middle100.pages.dev/5/" + fullPuzzleID + ".png";
				console.log("middlePieceIDUrl : " + middlePieceIDUrl);
			} else {

				middlePieceIDUrl = "https://middle100.pages.dev/1001/1001.png";
				mintingAllowed2 = false;
			}

			this.setState({ _mintingAllowed2: mintingAllowed2 });

			for (let x = 0; x < 8; x++) {
				pieceID[x] = await contractBidding.methods.puzzlePieceIDs(fullPuzzleID, x).call();
				pieceID2[x] = [pieceID[x], pieceID[x]];

				console.log("puzzlePieceIDs : " + pieceID[x]);

			}

			for (let x = 0; x < 8; x++) {

				let ownerOfThePiece;

				if (pieceID[x] <= totalSupplyNFT) {

					ownerOfThePiece = await contract.methods.ownerOf(pieceID[x]).call();

					//await new Promise(resolve => setTimeout(resolve, 500));

					if (ownerOfThePiece != account) {
						pieceID2[x] = [pieceID[x], 1001];
						console.log("NOT MATCH");
					}
					//await new Promise(resolve => setTimeout(resolve, 500));
					//this.setState({ _pieceID : pieceID });
				} else {
					pieceID2[x] = [pieceID[x], 1001];
					mintingAllowed2 = false;

				}

				this.setState({ _mintingAllowed2: mintingAllowed2 });

				this.setState({ _pieceID: pieceID2 });
				console.log("ownerOfThePiece : " + ownerOfThePiece);
			}

			let ownerOfTheMiddlePiece;
			ownerOfTheMiddlePiece = await contract.methods.ownerOf(this.state._middlePieceID).call();

		} catch (err) {
			console.log(err);
		}
	}

	mintPiece = async event => {
		event.preventDefault();
		this.setState({ _minting: 1 });

		/*let winnerMint = await contractBidding.methods.winner(this.state._selectedPuzzleID).call();
		this.setState({ _winnerMint: winnerMint });
		console.log("winnerMint : " + winnerMint);*/

	}

	mintPuzzle = async event => {
		event.preventDefault();
		this.setState({ _puzzleNFTS: 1 });

		/*let winnerMint = await contractBidding.methods.winner(this.state._selectedPuzzleID).call();
		this.setState({ _winnerMint: winnerMint });
		console.log("winnerMint : " + winnerMint);*/

	}

	mint = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _mint: 1 })
		this.setState({ _minting: 0 });
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 0 });


	}

	async FinalMinting(ID1, ID2, ID3, ID4, ID5, ID6, ID7, ID8, ID9) {

		try {
			await contract3.methods.mint(ID1, ID2, ID3, ID4, ID5, ID6, ID7, ID8, ID9).send({gasLimit: 685000, from: account, value: this.state.totalValue * 0 });
		} catch (err) {
			console.log(err);
		}
	}

	mint2 = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _mint: 1 })
		this.setState({ _minting: 0 });
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 0 });


	}

	startBidding = async event => {
		event.preventDefault();

		try {
			await contractBidding.methods.bid(this.state._selectedTokenID, this.state._selectedPuzzleID).send({ gasLimit: 685000, from: account, value: this.state._biddingAmount * 10 ** 18 });

			this.setState({ _biddingArena: 0 });
			this.setState({ _biddingInfo: 1 });

		} catch (err) {
			biddingNote = 1;
			await new Promise(resolve => setTimeout(resolve, 500));
			this.setState({ _biddingNote: biddingNote });
			console.log("I'm in CATCH");
		}

		if (biddingNote == 0) {

			localStorage.removeItem('puzzleID');
			localStorage.removeItem('tokenID');
			await new Promise(resolve => setTimeout(resolve, 500));
			console.log("In the bidding note - 0");
			console.log(
				localStorage?.getItem('puzzleID') + " and " + localStorage?.getItem('tokenID'));

			console.log("biddingNote :" + biddingNote);
			console.log("I'm NOT in CATCH");


		} else {
			this.setState({ _biddingInfo: 0 });
			this.setState({ _biddingArena: 1 });
			console.log("biddingNote :" + biddingNote);

		}


	}

	biddingTxnInfoasync = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 1 });
		this.setState({ _provenance: 0 });

	}


	async biddingP(puzzleID, tokenID) {

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _biddingArena: 1 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingInfo: 0 });
		this.setState({ _provenance: 0 });

		try {

			let puzzleID_1 = localStorage?.getItem('puzzleID');
			let tokenID_2 = localStorage?.getItem('tokenID');

			console.log("puzzleID_1Bidding : " + puzzleID_1);
			console.log("tokenID_2Bidding : " + tokenID_2);


			if (puzzleID_1 > 0) {
				puzzleID = puzzleID_1;
				console.log("puzzleID_1 >= 0");

				//	localStorage.removeItem('puzzleID');

				console.log("insideBiddingP:" + puzzleID);
			}

			if (tokenID_2 > 0) {
				tokenID = tokenID_2;
				console.log("tokenID_2 >= 0");

				//	localStorage.removeItem('tokenID');

				console.log("insideBiddingP:" + tokenID);
			}



		} catch (err) {
			console.log(err);
		}

		this.setState({ _selectedPuzzleID: puzzleID });
		this.setState({ _selectedTokenID: tokenID });

		if (puzzleID != null) {

			biddingMiddlePiece = "https://middle100.pages.dev/5/" + puzzleID + ".png";

		}
		this.setState({ _biddingMiddlePiece: biddingMiddlePiece });
		console.log("biddingMiddlePiece : " + biddingMiddlePiece);


		try {
			/*nextMinBidValue = await contractBidding.methods.nextMinBidValue(this.state._selectedPuzzleID).call();
			let nextMinBidValueLocal = nextMinBidValue / 1000000000000000000;

			if (nextMinBidValue != 0 || nextMinBidValue != undefined) {
				this.setState({ _nextMinBidValue: nextMinBidValueLocal });
			} else {
				this.setState({ _nextMinBidValue: 0.1 });
			}

			console.log("nextMinBidValue :" + nextMinBidValue);*/


			biddingClosingTimeByOwner = await contractBidding.methods.biddingClosingTimeByOwner(puzzleID).call();
			console.log("biddingClosingTimeByOwner999 : " + biddingClosingTimeByOwner);

			let gap1 = await contractBidding.methods.bidGap().call();
			gap = gap1 / 1000000000000000000;
			this.setState({ _gap: gap });
			console.log("gap :" + gap);

			winningValue = await contractBidding.methods.winningValue(puzzleID).call();
			let winningValueLocal = winningValue / 1000000000000000000;
			nextValue = gap * 1 + winningValueLocal * 1;
			this.setState({ _nextValue: nextValue });
			console.log("nextValue : " + nextValue);
			this.setState({ _winningValue: winningValueLocal });
			console.log("winningValue :" + winningValueLocal);


			//let biddingTime = (biddingClosingTimeByOwner * 1000) - Date.now();
			//biddingClosingTimeByOwner = new Date(biddingClosingTimeByOwner);

			bidCount = await contractBidding.methods.bidCount(puzzleID).call();
			this.setState({ _bidCount: bidCount });
			console.log("bidCount : " + bidCount);

			for (let x = 0; x < bidCount; x++) {

				bidDepositedList[x] = await contractBidding.methods.bidDepositer(puzzleID, x).call();
				nickNameList[x] = await contractBidding.methods.nickNameWithWallet(bidDepositedList[x]).call();

				bidAmountsList[x] = await contractBidding.methods.bidList(puzzleID, x).call();
				console.log("bidDepositer :" + bidDepositer);
				console.log("bidDepositer :" + bidDepositer);

				bidFullList[x] = [nickNameList[x], bidAmountsList[x]];
				await new Promise(resolve => setTimeout(resolve, 50));
				this.setState({ _bidFullList: bidFullList.reverse() });
				console.log("ElementArray" + this.state._bidFullList);

			}

			if (biddingClosingTimeByOwner > 0) {
				this.setState({ _biddingClosingTimeByOwner: biddingClosingTimeByOwner * 1000 });
				biddingClosingTimeByOwner = biddingClosingTimeByOwner * 1000;
				console.log("biddingClosingTimeByOwner :" + biddingClosingTimeByOwner);
			}

			localStorage.setItem('puzzleID', puzzleID);
			console.log("puzzleID : " + localStorage?.getItem('puzzleID'));

			localStorage.setItem('tokenID', tokenID);
			console.log("tokenID : " + localStorage?.getItem('tokenID'));

			let winner = await contractBidding.methods.winner(this.state._selectedPuzzleID).call();
			this.setState({ _winner: winner });
			console.log("winner : " + winner);

			let winnerNickname = await contractBidding.methods.winnerNickname(this.state._selectedPuzzleID).call();
			let hex_string = winnerNickname.slice(2);
			let nameOfTheWinner = Buffer.from(hex_string, 'hex').toString('utf8');
			this.setState({ _winnerNickname: nameOfTheWinner });
			console.log("winnerNickname : " + nameOfTheWinner);

		} catch (err) {
			console.log(err);
		}

	}

	async biddingPSpecial(puzzleID, tokenID) {

		this.setState({ _biddingArena: 1 });

		try {

			this.setState({ _selectedPuzzleID: puzzleID });
			this.setState({ _selectedTokenID: tokenID });
			biddingMiddlePiece = "https://middle100.pages.dev/5/" + puzzleID + ".png";
			this.setState({ _biddingMiddlePiece: biddingMiddlePiece });
			console.log("biddingMiddlePiece : " + biddingMiddlePiece);


			let puzzleID_1 = localStorage?.getItem('puzzleID');
			let tokenID_2 = localStorage?.getItem('tokenID');

			console.log("puzzleID_1Bidding : " + puzzleID_1);
			console.log("tokenID_2Bidding : " + tokenID_2);

			if (puzzleID_1 > 0) {
				puzzleID = puzzleID_1;
				console.log("puzzleID_1 >= 0");

				//	localStorage.removeItem('puzzleID');

				console.log("insideBiddingP:" + puzzleID);
			}

			if (tokenID_2 > 0) {
				tokenID = tokenID_2;
				console.log("tokenID_2 >= 0");

				//	localStorage.removeItem('tokenID');

				console.log("insideBiddingP:" + tokenID);
			}

		} catch (err) {
			console.log(err);
		}



		try {
			/*nextMinBidValue = await contractBidding.methods.nextMinBidValue(this.state._selectedPuzzleID).call();
			let nextMinBidValueLocal = nextMinBidValue / 1000000000000000000;

			if (nextMinBidValue != 0 || nextMinBidValue != undefined) {
				this.setState({ _nextMinBidValue: nextMinBidValueLocal });
			} else {
				this.setState({ _nextMinBidValue: 0.1 });
			}

			console.log("nextMinBidValue :" + nextMinBidValue);*/

			winningValue = await contractBidding.methods.winningValue(puzzleID).call();
			let winningValueLocal = winningValue / 1000000000000000000;
			this.setState({ _winningValue: winningValueLocal });
			console.log("winningValue :" + winningValueLocal);

			biddingClosingTimeByOwner = await contractBidding.methods.biddingClosingTimeByOwner(puzzleID).call();



			//let biddingTime = (biddingClosingTimeByOwner * 1000) - Date.now();
			//biddingClosingTimeByOwner = new Date(biddingClosingTimeByOwner);

			bidCount = await contractBidding.methods.bidCount(puzzleID).call();

			for (let x = 0; x < bidCount; x++) {

				bidDepositedList[x] = await contractBidding.methods.bidDepositer(puzzleID, x).call();
				nickNameList[x] = await contractBidding.methods.nickNameWithWallet(bidDepositedList[x]).call();


				bidAmountsList[x] = await contractBidding.methods.bidList(puzzleID, x).call();
				console.log("bidDepositer :" + bidDepositer);
				console.log("bidDepositer :" + bidDepositer);

				bidFullList[x] = [nickNameList[x], bidAmountsList[x]];
				await new Promise(resolve => setTimeout(resolve, 50));
				this.setState({ _bidFullList: bidFullList });
				console.log("ElementArray" + this.state._bidFullList);

			}

			if (biddingClosingTimeByOwner > 0) {
				this.setState({ _biddingClosingTimeByOwner: biddingClosingTimeByOwner * 1000 });
				biddingClosingTimeByOwner = biddingClosingTimeByOwner * 1000;
				console.log("biddingClosingTimeByOwner :" + biddingClosingTimeByOwner);
			}

			localStorage.setItem('puzzleID', puzzleID);
			console.log("puzzleID : " + localStorage?.getItem('puzzleID'));

			localStorage.setItem('tokenID', tokenID);
			console.log("tokenID : " + localStorage?.getItem('tokenID'));

		} catch (err) {
			console.log(err);
		}

	}

	bidding = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 1 });
		this.setState({ _biddingInfo: 0 });
		this.setState({ _provenance: 0 });

		localStorage.removeItem('puzzleID');
		localStorage.removeItem('tokenID');

		//	localStorage.setItem('puzzleID', puzzleID);
		console.log("puzzleIDLocalStorage : " + localStorage?.getItem('puzzleID'));

		//		localStorage.setItem('tokenID', tokenID);
		console.log("tokenIDLocalStorage : " + localStorage?.getItem('tokenID'));

	}

	setNickName = async event => {
		event.preventDefault();

		try {

			const string = this.state._nickName;
			const bytes = Buffer.from(string, 'utf8');

			//await contractBidding.methods.setNickNameWithWallet(this.state._nickName).send({ gasLimit: 685000, from: account });
			await contractBidding.methods.setNickNameWithWallet(bytes).send({ gasLimit: 685000, from: account });
			this.setState({ _nickNameWithWallet: nickNameWithWallet });
			successfullyRegistered = 1;
			this.setState({ _successfullyRegistered: successfullyRegistered });
			console.log("nickNameWithWallet : " + nickNameWithWallet);
		} catch (err) {
			console.log(err);
		}

		//window.location.reload();



	}

	biddingSecondDiv = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 1 });
		this.setState({ _biddingInfo: 0 })
		this.setState({ _provenance: 0 });

	}

	closeWindow = async event => {
		event.preventDefault();

		this.setState({ _bidding: 0 });
		this.setState({ _minting: 0 });
		this.setState({ _mintDialogue: 0 });
		this.setState({ _puzzleNFTS: 0 });
		this.setState({ _collectScreen: 0 });
		this.setState({ _successfullyRegistered: 0 });
	}

	home = async event => {
		event.preventDefault();

		this.setState({ _mint: 0 });
		this.setState({ _about: 0 });
		this.setState({ _tsr: 0 });
		this.setState({ _faq: 0 });
		this.setState({ _bidding: 0 });
		this.setState({ _biddingArena: 0 });
		this.setState({ _provenance: 0 });

	}

	pic1 = async event => {
		event.preventDefault();

		this.setState({ _pic1: 1 });

		await new Promise(resolve => setTimeout(resolve, 250));

		this.ferrisWheel();

		afterClick();


	}

	pic2 = async event => {
		event.preventDefault();

		this.setState({ _pic2: 1 });

		await new Promise(resolve => setTimeout(resolve, 250));

		this.ferrisWheel();

		afterClick1();

	}

	pic3 = async event => {
		event.preventDefault();

		this.setState({ _pic3: 1 });
		await new Promise(resolve => setTimeout(resolve, 250));

		this.ferrisWheel();

		afterClick2();

	}

	pic4 = async event => {
		event.preventDefault();

		this.setState({ _pic4: 1 });
		await new Promise(resolve => setTimeout(resolve, 250));

		this.ferrisWheel();

		afterClick3();

	}

	pic5 = async event => {
		event.preventDefault();

		this.setState({ _pic5: 1 });
		await new Promise(resolve => setTimeout(resolve, 250));

		this.ferrisWheel();

		afterClick4();

	}

	pic6 = async event => {
		event.preventDefault();

		this.setState({ _pic6: 1 });
		await new Promise(resolve => setTimeout(resolve, 250));

		this.ferrisWheel();

		afterClick5();

	}

	pic7 = async event => {
		event.preventDefault();

		this.setState({ _pic7: 1 });
		await new Promise(resolve => setTimeout(resolve, 250));

		this.ferrisWheel();

		afterClick6();

	}

	pic8 = async event => {
		event.preventDefault();

		this.setState({ _pic8: 1 });
		await new Promise(resolve => setTimeout(resolve, 250));


		this.ferrisWheel();

		afterClick7();

	}

	bloodBtn = async event => {
		event.preventDefault();

		this.setState({ _bloodBtn: 1 });

	}

	code = async event => {
		event.preventDefault();

		this.setState({ _wrongMsgs: wrongMsgs });
		this.setState({ _correctMsgs: correctMsgs });

		if (this.state._code == "52") {
			this.setState({ _msg: "You can count. Don't be so proud. Still, tell the Cartoonist. He'd be interested." });
			this.setState({ _msgCode: 1 });

		} else

			if (this.state._code == "George Elliot") {
				this.setState({ _msg: "Huh. Didn't expect you to figure that out. But anyways, do you read?" });
				this.setState({ _msgCode: 1 });

			} else

				if (this.state._code == "Middlemarch") {
					this.setState({ _msg: "It's always the last breath that draws the most meaning. Published by New York and Boston H. M. Caldwell Company Publishers." });
					this.setState({ _msgCode: 1 });

				}
				else

					if (this.state._code == "But the effect of her being on those around her was incalculably diffusive: for the growing good of the world is partly dependent on unhistoric acts; and that things are not so ill with you and me as they might have been, is half owing to the number who lived faithfully a hidden life, and rest in unvisited tombs") {
						this.setState({ _msg: "Long one wasn't it? That one took you years to write. Almost like you gave birth. But you didn't, someone else did. #=years" });
						this.setState({ _msgCode: 1 });

					}

					else

						if (this.state._code == "Wu Yuanji") {
							this.setState({ _msg: "I love bold, italics, and underline. But my FIRST option would be italics." });
							this.setState({ _msgCode: 1 });

						}

						else

							if (this.state._code == "De facto") {
								this.setState({ _msg: "Wow. You're good. Go tell the Cartoonist the last thing you wrote. You are a star." });
								this.setState({ _msgCode: 1 });

							}
							else {

								let e = Math.floor(Math.random() * 50);
								this.setState({ _msg: this.state._wrongMsgs[e] });
								this.setState({ _msgCode: 0 });
								console.log("msg: " + this.state._msg);
							}

	}

	async ferrisWheel() {


		if (this.state._pic1 == 1 && this.state._pic2 == 1 && this.state._pic3 == 1 && this.state._pic4 == 1 && this.state._pic5 == 1 && this.state._pic6 == 1 && this.state._pic7 == 1 && this.state._pic8 == 1) {
			this.setState({ _ferrisWheel: 0 });

		}

		console.log("pic1 : " + this.state._pic1);
		console.log("pic2 : " + this.state._pic2);
		console.log("pic3 : " + this.state._pic3);
		console.log("pic4 : " + this.state._pic4);
		console.log("pic5 : " + this.state._pic5);
		console.log("pic6 : " + this.state._pic6);
		console.log("pic7 : " + this.state._pic7);
		console.log("pic8 : " + this.state._pic8);

	}

	start = async event => {
		event.preventDefault();

		soundfinder = 1;
		this.setState({ sound: soundfinder });
		song = new Audio(audio);
		song.play();
	}

	pause = async event => {
		event.preventDefault();

		soundfinder = 0;
		this.setState({ sound: soundfinder });
		song.pause();
	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="mainCurtain" >

						<div class="leftCurtain"></div>

						<div class="rightCurtain"></div>
					</div>
					<div class="spaceCover"></div>

					<div class="headers">

						<div class="logoDiv"> </div>

						<div class="right">
							<div class="connect">

								<button class="connectbtnActive" onClick={this.home}>HOME</button>
								{this.state.walletAddress === '' ?
									(<form class="connectbtn" onSubmit={this.wallet2Connect}>
										<button class="connectbtn2">Connect</button>
									</form>) : (<form class="connectbtn" onSubmit={this.walletDisconnect}><button class="connectbtn2">
										{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}
								<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
								<button class="connectbtn" onClick={this.faq}>FAQ</button>

								<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
								<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

								{this.state.walletAddress !== '' ?
									(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}
								<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>

							</div>
							<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
							<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
						</div>

						<div class="rightMobile">
							<div class="line1Mobile">
								<button class="connectbtnActive" onClick={this.home}>HOME</button>
								<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
								<button class="connectbtn" onClick={this.faq}>FAQ</button>

							</div>
							<div class="line2Mobile">
								<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
								<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

								{this.state.walletAddress !== '' ?
									(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}

							</div>
							<div class="line1Mobile">
								<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>

								{this.state.walletAddress === '' ?
									(<form class="connectbtn3" onSubmit={this.wallet2Connect}>
										<button class="connectbtn">Connect</button>
									</form>) : (<form class="connectbtn3" onSubmit={this.walletDisconnect}><button class="connectbtn">
										{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}
								<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
								<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
							</div>
						</div>
					</div>

					<div class="introMain">
						<img class="mainPicMobile" src={nft} />
						<div class="introMain2">
							<div class="intro">
								CARTOONISTICS
							</div>
							<div class="intro2"></div>
							<p class="line-1 anim-typewriter">A collection of 100 Full Pictures that are a representation of humanity</p>
							<p class="intro2">A collection of 100 Full Pictures <br /> that are a representation of humanity</p>

						</div>
						<img class="mainPic" src={nft} />

					</div>

					{this.state._tsr > 0 ?
						(<div class="boxWrap2Main">
							<div class="headers">

								<div class="logoDiv"></div>

								<div class="right">
									<div class="connect">

										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtnActive" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>

									</div>
									<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
									<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
								</div>

								<div class="rightMobile">
									<div class="line1Mobile">
										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtnActive" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

									</div>
									<div class="line2Mobile">
										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}

									</div>

									<div class="line1Mobile">
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>
										<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
										<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
									</div>

								</div>
							</div>
							<div class="boxWrap2Rm">

								<div class="storyRight">
									<div class="storyH2">The Silk Road
									</div>

									<div class="storyRight2">
										<p class="storyCon"><span class="bigWord">T</span>he Silk Road was a network of routes commonly used by merchants that connected the East and West civilisations for almost 1500 years. Its last dated usage was 1453 C.E., when the Ottoman Empire closed trade with the West. 600 years later, the Cartoonist reenvisages the Silk Road to connect people from all cultures, ethnicities, and backgrounds on a road with no destination.</p>

										<p class="storyCon"><p class="line-2 anim-typewriter2">Our journey begins in Beijing and Shanghai</p></p>
										<p class="storyConMob">Our journey begins in Beijing and Shanghai</p>

									</div>

									<div class="mapSet">

										<img class="pin1" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Beijing:</div>
											</div>
											<p class="rmC">Reach 50,000 members on Discord, and 100,000 followers on Twitter.</p>
										</div>

										<img class="pin2" src={circle} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Shanghai:</div>
											</div>
											<p class="rmC">Handpicking 90 individuals worthy of being whitelisted. To be worthy, you must be influential and active in promoting this project. By the same token, it doesn’t mean you need thousands of followers to do that, your creativity is what will set you apart. If the Cartoonist didn’t choose you, it doesn’t mean you weren’t good enough. It just means there was someone better than you.</p>
										</div>

										<img class="pinQuestion" src={circle} />
										<div class="rmBox1">
											<div class="q">
												<img src={pin2} />
												<div class="rmT2">?</div>
											</div>
										</div>

										<img class="pin3" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Chang’an:</div>
											</div>
											<p class="rmC">Public minting of the other 720 puzzle pieces goes live.</p>
										</div>

										<img class="pin4" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Lanzhou:</div>
											</div>
											<p class="rmC">Puzzle pieces are placed in “Le Louvre” by the holders.</p>
										</div>

										<img class="pin5" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Jiuquan:</div>
											</div>
											<p class="rmC">Once 8 puzzle pieces are placed in a single picture, the final and middle piece is auctioned off to the holders of the puzzle pieces. In the instance that one wallet owns all 8 puzzle pieces, the middle piece will be sold at floor price.</p>
										</div>

										<img class="pin6" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Yumen:</div>
											</div>
											<p class="rmC">After all middle pieces have been auctioned off, the Full Picture collection will be revealed.</p>
										</div>

										<img class="pin7" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Anxi:</div>
											</div>
											<p class="rmC">Once all 9 puzzle pieces are owned by a single wallet, the holder will be airdropped the Full Picture they deservedly own.</p>
										</div>

										<img class="pin8" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Hami:</div>   </div>
											<p class="rmC">Limited edition clothes and accessories (socks, bucket hat, shirts, pants, and other good stuff) released</p>
										</div>

										<img class="pin9" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Turfan:</div> </div>
											<p class="rmC">Partnership-organised events for members (TBA)</p>
										</div>

										<img class="pin10" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Kucha:</div> </div>
											<p class="rmC">Partnership-organised events for members (TBA)</p>
										</div>

										<img class="pin11" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Aksu:</div> </div>
											<p class="rmC">Partnership-organised events for members (TBA)</p>
										</div>

										<img class="pin12" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Khotan:</div>  </div>
											<p class="rmC"> Once 75% of the Full Pictures are airdropped, the Cartoonist will set a time period before beginning Phase II in Kashgar.</p>
										</div>

										<img class="pin13" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Kashgar:</div> </div>
											<p class="rmC">All the Full Picture owners will be contacted and asked to express a personal experience from their past or present. Each personal experience described will be drawn and
												form part of the next batch of Cartoonistics. Anonymity is guaranteed (unless you’d prefer for the Cartoonist to name you).</p>
										</div>

										<img class="pin14" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Osh:</div></div>
											<p class="rmC">Each contributor will be airdropped a puzzle piece from their own described picture (not the middle piece) prior to public sale.</p>
										</div>

										<img class="pin15" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Samarkand:</div></div>
											<p class="rmC">Post public sale, each contributor will receive 2.5% in sub-creator earnings for all transactions on their picture</p>
										</div>

										<img class="pin16" src={pin} />
										<div class="rmBox1">
											<div class="rmT">
												<img src={pin2} />
												<div class="rmT2">Mashhad:</div></div>
											<p class="rmC">The process rebegins in Shanghai with objectives readjusted as seen fit by the Cartoonist. </p>
										</div>



										<img class="map" src={map} />
									</div>

								</div>

							</div>

							<div class="footer">
								<div class="footer2">
									<div class="copyright">...to have music...</div>
								</div>
							</div>
						</div>) : null}

					{this.state._faq > 0 ?
						(<div class="boxWrap2Main">
							<div class="headers">

								<div class="logoDiv"></div>

								<div class="right">
									<div class="connect">

										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtnActive" onClick={this.faq}>FAQ</button>

										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>
									</div>
									<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
									<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
								</div>

								<div class="rightMobile">
									<div class="line1Mobile">
										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtnActive" onClick={this.faq}>FAQ</button>

									</div>
									<div class="line2Mobile">
										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}

									</div>

									<div class="line1Mobile">
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>
										<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
										<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
									</div>

								</div>
							</div>
							<div class="boxWrap2">

								<div class="storyRight">
									<div class="storyH2">FAQ</div>

									<div class="storyRight3">

										<div class="accordin reveal" >
											<details >
												<summary>How much are secondary royalties?</summary>
												<div class="faq__content ">
													<p>5% to the Cartoonist for the first collection</p>
													<p>2.5% to the Cartoonist and 2.5% to the sub-creator (Check the Silk Road for more info) for the second collection and onwards.</p>
													<p>The Cartoonist’s goal is to be one with their community, not above. That’s why we share royalties.</p>
												</div>
											</details >

											<details >
												<summary>What are the maximum mints per wallet?</summary>
												<div class="faq__content">
													<p>One mint per wallet. The Cartoonist doesn’t like greediness.</p>

												</div>
											</details>
											<details >
												<summary>When I buy from the public sale, do I get to pick which puzzle piece I buy?</summary>
												<div class="faq__content">
													<p>No. You will randomly receive a puzzle piece. This makes for less picky buyers.</p>
												</div>
											</details>

											<details >
												<summary>Are there rarities to the puzzle pieces?</summary>
												<div class="faq__content">
													<p>Aside from the middle puzzle pieces, all other puzzle pieces are created equally. The Cartoonist did not assign any rarity, but the community might decide a Full Picture is culturally or personally more significant to them, thus driving rarity and price.</p>

												</div>
											</details>

											<details >
												<summary>Are there any privileges that My Friend Parrot grants me?</summary>
												<div class="faq__content">
													<p>Aside from the middle puzzle pieces, all other puzzle pieces are created equally. The Cartoonist did not assign any rarity, but the community might decide a Full Picture is culturally or personally more significant to them, thus driving rarity and price.</p>

												</div>
											</details>

											<details reveal>
												<summary>In the first collection, how many puzzle pieces and Full Pictures will there be in total?</summary>
												<div class="faq__content">
													<p>900 puzzle pieces (90 from whitelisting, 720 puzzle pieces from public sale, 90 middle pieces) 100 Full Pictures</p>

												</div>
											</details>

											<details reveal>
												<summary>How many puzzle pieces per one Full Picture?</summary>
												<div class="faq__content">
													<p>9 (One middle piece and 8 side pieces)</p>

												</div>
											</details>

											<details reveal>
												<summary>In the second collection and onwards, how many puzzle pieces and Full Pictures will there be in total?</summary>
												<div class="faq__content">
													<p>Instead of whitelisting, the contributors will be airdropped their puzzle piece. Thus, the number of Full Pictures is contingent on the number of contributions received by Full Picture owners.</p>

												</div>
											</details>

											<details reveal>
												<summary>What happens if there aren’t enough contributors?</summary>
												<div class="faq__content">
													<p>In the event that there aren’t enough contributors, the Cartoonist will reach out to random individuals within the community to ask for their contributions. These people will not receive the benefits of 2.5% nor the free puzzle piece but will receive notable recognition within the community (At their consent).</p>
													<p>Networking, baby.</p>
												</div>
											</details>


										</div>
									</div>
								</div>


							</div>

							<img class="stickyHand" src={stickyHand} />
							<div class="vidCont">
								<video class="videoBg" src={video} autobuffer autoPlay loop muted playsInline></video></div>
							<div class="footer">
								<div class="footer2">
									<div class="copyright">"...or poetry..."</div>
								</div>
							</div>
						</div>) : null}

					{this.state._provenance > 0 ?
						(<div class="boxWrap2Main">
							<div class="headers">

								<div class="logoDiv"></div>

								<div class="right">
									<div class="connect">

										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

										<button class="connectbtnActive" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>

									</div>
									<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
									<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
								</div>

								<div class="rightMobile">
									<div class="line1Mobile">
										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtnActive" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

									</div>
									<div class="line2Mobile">
										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}

									</div>

									<div class="line1Mobile">
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>
										<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
										<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
									</div>

								</div>
							</div>
							<div class="boxWrap2Rm">

								<div class="storyRight">
									<div class="storyH2">CARTOONIST PROVENANCE RECORD

									</div>

									<div class="storyRight2">
										<p class="storyCon">Contract Puzzle Pieces : <a href="https://goerli.etherscan.io/address/0x581B88FC7a8659988C3258C924eCC08652511D83">0x581B88FC7a8659988C3258C924eCC08652511D83</a></p>
										<p class="storyCon">Contract Middle Piece : <a href="https://goerli.etherscan.io/address/0x96fd97fc44d89aa540b7cd896675d2c68b34ba20">0x96FD97FC44d89AA540b7Cd896675d2C68b34ba20</a> </p>
										<p class="storyCon">Contract Full Puzzle : <a href="https://goerli.etherscan.io/address/0xdD856B186faB9a213e2fCB23c06Dd279A947E07e">0xdD856B186faB9a213e2fCB23c06Dd279A947E07e</a></p>
 
									</div>
									<div class="vidCont2">
										<video class="videoBg" src={video3} autobuffer autoPlay loop muted playsInline></video></div>

									
								</div>

							</div>

						</div>) : null}

					{this.state._about > 0 ?
						(<div class="boxWrap2Main">
							<div class="headers">

								<div class="logoDiv"></div>

								<div class="right">
									<div class="connect">

										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}
										<button class="connectbtnActive" onClick={() => window.location.href = '/about'}>ABOUT</button>
									</div>
									<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
									<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
								</div>

								<div class="rightMobile">
									<div class="line1Mobile">
										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

									</div>
									<div class="line2Mobile">
										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}

									</div>

									<div class="line1Mobile">
										<button class="connectbtnActive" onClick={() => window.location.href = '/about'}>ABOUT</button>
										<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
										<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
									</div>

								</div>
							</div>
							<div class="boxWrap2">

								<div class="storyRight">
									<div class="storyH2">About
									</div>

									<div class="storyRight2-2">
										<div class="about1">
											<p class="storyCon"><span class="bigWord">T</span>he Cartoonistics are a collection of 100 Full Pictures* that are a representation of humanity. Each picture is split into 9 puzzle pieces, and the Cartoonist has tasked you with reassembling the pieces. Alone the pieces don’t mean much, but together…</p>
											<p class="storyCon">Once all the puzzles have been placed in Le Louvre and the Full Pictures are formed, the Cartoonist reaches out to holders and seeks their opinion on the next collection. Each contributor will own a part of the next collection, and the process repeats.</p>
											<p class="storyCon">The Cartoonist is aware that the majority of NFT investors don’t have double-digits in Ethereum sitting in their wallet to spare. As such, the cycle of creating new collections will allow more investors of lesser wealth to be involved in the community space.</p>
											<p class="storyCon-1"><img class="paper" src={paper} /></p>
											<p class="storyCon-1"><img class="paper" src={paper1} /></p>
											<p class="storyCon-1"><img class="paper" src={paper2} /></p>
											<p class="storyCon-1"><img class="paper" src={paper3} /></p>
											<p class="storyCon-1"><img class="paper" src={paper4} /></p>

										</div>
										<div class="about2">

											{this.state._ferrisWheel > 0 ?
												(<div class="man"><div class="wrap-outer">
													<div class="wrapper">
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic1} class="carriage"></div>
															<div class="bar"></div>
														</div>
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic2} class="carriage1"></div>
															<div class="bar"></div>
														</div>
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic3} class="carriage2"></div>
															<div class="bar"></div>
														</div>
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic4} class="carriage3"></div>
															<div class="bar"></div>
														</div>
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic5} class="carriage4"></div>
															<div class="bar"></div>
														</div>
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic6} class="carriage5"></div>
															<div class="bar"></div>
														</div>
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic7} class="carriage6"></div>
															<div class="bar"></div>
														</div>
														<div class="out-wrap">
															<div class="circle-outer"></div>
															<div onClick={this.pic8} class="carriage7"></div>
															<div class="bar"></div>
														</div>
													</div>
													<div class="circle-center"></div>
													<div class="circle-ring"></div>
													<div class="triangle"></div>
												</div></div>) :

												(<div class="blodDiv"><img onClick={this.bloodBtn} src={bloodBtn} /></div>)

											}

											<p class="storyCon">The Cartoonist’s vision is to revitalise the NFT community from the garbage that flooded its gates. Far too many “creators” assume dishing out animals with a party hat on or variances of popular projects might make them wealth quickly. Little do they know; they have corrupted the integrity of the digital space.</p>
											<p class="storyCon">No more.</p>
											<p class="storyCon">A new era is coming. The Cartoonist is here to stay. </p>


											<div class="quotesAndAudio">

												<div class="container" id="js-container">
													<canvas class="canvas" id="js-canvas" ></canvas>

													<div class="form" style={{ visibility: 'hidden' }}>

														<p class="storyCon-1"><img class="paper" src={paperNew} /></p>
														<div class="audioPic">
															{this.state.sound === 0 ?
																(
																	<div>
																		<button class="speakerplace2" onClick={this.start}></button></div>)
																:

																(<div>

																	<button class="speakerplace1" onClick={this.pause} ></button></div>)}

														</div>
													</div>

												</div>

											</div>
											<p class="storyCon">*2 of them are held back for the Cartoonist to do with as he pleases.</p>
										</div>
									</div>
								</div>

							</div>

							<div class="footer">
								<div class="footer2">
									<div class="copyright">"...interrupted"</div>
								</div>
							</div>
						</div>) : null}

					{this.state._bidding > 0 ?
						(<div class="boxWrap2Main">

							<div class="bidMain">
								<div class="bidMain2">
									<div class="bidT">My Collected Pieces</div>

									<div class="tokenIds" >
										{this.state._elementArray.map((element1) => {

											console.log("ID :" + element1[0]);
											console.log("Time :" + element1[1]);

											//var ID = element1[0] + 1;

											if (true) {

												if (element1[0] == 0) {
													var url = "https://normal800.pages.dev/800/" + element1[0] + ".png"; //"https://myfriendparrot.com/images/" + 6666 + ".png";
													console.log("url :" + url);


												} else {

													var url = "https://normal800.pages.dev/800/" + element1[0] + ".png"; //"https://myfriendparrot.com/images/" + element1[0] + ".png";
													console.log("url :" + url);

												}

											} else {
												url = secretGif;
											}

											console.log("url :" + url);
											console.log("element1 :" + element1[0]);
											console.log("rewards1 :" + element1[1]);

											return (
												<div class="tokenIds2">
													<div class="flip-card">
														<div class="flip-card-inner">
															<div class="flip-card-front">
																<div class="unStakeImg"><img class="images_show" src={url} /></div>
															</div>

															<div class="flip-card-back">

																<div class="elementName2">Puzzle # {element1[1]}</div>
																<div class="elementName3">ID : {element1[0]}</div>
																<button class="stake3" onClick={() => this.biddingP(element1[1], element1[0])}>Bid</button>
															</div>
														</div>
													</div></div>);

										})}
									</div>

									<form onSubmit={this.closeWindow}>
										<button class="closeNote">✖</button>
									</form>
								</div>
							</div>

						</div>) : null}

					{this.state._biddingArena > 0 ?
						(<div class="boxWrap2Main">

							<div class="headers">

								<div class="logoDiv"></div>

								<div class="right">
									<div class="connect">

										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>

									</div>
									<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
									<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
								</div>

								<div class="rightMobile">
									<div class="line1Mobile">
										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

									</div>
									<div class="line2Mobile">
										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtn" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}

									</div>

									<div class="line1Mobile">
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>
										<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
										<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
									</div>

								</div>
							</div>
							<div class="boxWrap2">
								<div class="storyRight">
									<div class="storyH2">Bidding Area
									</div>

									<div class="storyRight2-2">
										<div class="about1">
											<p class="storyCon"><span class="bigWord">H</span>ere is the place where all puzzle holders are able to place their puzzle to complete Full Pictures.</p>


										</div>
										<div class="about2">

										</div>
									</div>
								</div>

								<div class="biddingMain">

									<img class="puzzleBid" src={this.state._biddingMiddlePiece} />

									<div class="bidArea">
										<div class="bidArea1">Cartoonist #{this.state._selectedPuzzleID}</div>
										<div class="bidDetails">
											{this.state._biddingClosingTimeByOwner > Date.now() ?

												(<div>
													<div class="bidArea2">Current Bid</div>
													<div class="bidArea3">Ξ {this.state._winningValue} </div>
												</div>) : (<div>

													{this.state._bidCount > 0 ?

														(<div><div class="bidArea2Dis">Current Bid</div>
															<div class="bidArea3Dis">Ξ {this.state._winningValue}</div></div>) :
														(<div><div class="bidArea2">Current Bid</div>
															<div class="bidArea3">Ξ {this.state._winningValue} </div></div>
														)}
												</div>)}
											<div class="split"></div>
											<div>
												<div class="bidArea2">Auction Ends In</div>

												{this.state._biddingClosingTimeByOwner > Date.now() ?
													(<div class="bidArea3"><Countdown
														date={this.state._biddingClosingTimeByOwner /*1665345600000*/}
														renderer={renderer}
													/></div>) :

													(<div>{this.state._bidCount > 0 ?
														(<div class="bidArea3"> Bidding Closed </div>) : (<div class="bidArea3"> - </div>)}
													</div>)}
											</div>
										</div>

										{this.state._biddingClosingTimeByOwner > Date.now() ?

											(<div><form onSubmit={this.startBidding}><div class="amountSection">
												<div><input class="maxCon3" name="yourName" placeholder={this.state._nextValue.toFixed(1)} value={this.state._biddingAmount}
													onChange={event => this.setState({ _biddingAmount: event.target.value })} />
												</div>
												<button class="bidBtn">Place Bid</button>
											</div></form>
												<p class="next">Next minimum bid {this.state._nextValue.toFixed(1)}</p></div>) :

											(<div>{this.state._bidCount > 0 ?
												(<div><form onSubmit={this.startBidding}><div class="amountSection">
													<div><input disabled class="maxCon3" name="yourName" placeholder={this.state._nextValue.toFixed(1)} value={this.state._biddingAmount}
														onChange={event => this.setState({ _biddingAmount: event.target.value })} />
													</div>
													<button class="bidBtnDis" disabled>Place Bid</button>
												</div></form>
													<p class="nextDis">Next minimum bid {this.state._nextValue.toFixed(1)}</p></div>) :
												(<div><form onSubmit={this.startBidding}><div class="amountSection">
													<div><input class="maxCon3" name="yourName" placeholder={this.state._nextValue.toFixed(1)} value={this.state._biddingAmount}
														onChange={event => this.setState({ _biddingAmount: event.target.value })} />
													</div>
													<button class="bidBtn">Place Bid</button>
												</div></form>
													<p class="next">Next minimum bid {this.state._nextValue.toFixed(1)}</p></div>)

											}
											</div>)}

									</div>

								</div>

								{this.state._biddingClosingTimeByOwner > Date.now() ?

									(<div>{this.state._bidFullList.map((element2) => {

										let hex_string = element2[0].slice(2);
										let nameFromTheList = Buffer.from(hex_string, 'hex').toString('utf8');			
										console.log("ID :" + nameFromTheList);
										console.log("Time :" + element2[1]);

										return (
											<div class="previousBid">
												<div class="previousAddress">{nameFromTheList}</div>
												<div class="previousNumber">Ξ {element2[1] / 1000000000000000000}</div>
											</div>);

									})}</div>) :
									(<div>{this.state._bidCount > 0 ?
										(<div class="winner">{this.state._winnerNickname} Won the bid for Ξ {this.state._winningValue}</div>) :
										(<div>{this.state._bidFullList.map((element2) => {

										let hex_string = element2[0].slice(2);
										let nameFromTheList = Buffer.from(hex_string, 'hex').toString('utf8');			
										console.log("ID :" + nameFromTheList);
										console.log("Time :" + element2[1]);

											return (
												<div class="previousBid">
													<div class="previousAddress">{nameFromTheList}</div>
													<div class="previousNumber">Ξ {element2[1] / 1000000000000000000}</div>
												</div>);

										})}</div>)

									}</div>)}

							</div>

						</div>) : null}

					{this.state._mint > 0 ?
						(<div class="boxWrap2Main">

							<div class="headers">

								<div class="logoDiv"></div>

								<div class="right">

									<div class="connect">
										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtnActive" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>
									</div>

									<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
									<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
								</div>

								<div class="rightMobile">
									<div class="line1Mobile">
										<button class="connectbtn" onClick={this.home}>HOME</button>
										<button class="connectbtn" onClick={this.tsr}>THE SILK ROAD</button>
										<button class="connectbtn" onClick={this.faq}>FAQ</button>

									</div>
									<div class="line2Mobile">
										<button class="connectbtn" onClick={this.provenance}>PROVENANCE</button>
										<button class="connectbtnActive" onClick={this.mint}>LE LOUVRE</button>

										{this.state.walletAddress !== '' ?
											(<button class="connectbtn" onClick={this.bidding}>AUCTION</button>) : null}

									</div>

									<div class="line1Mobile">
										<button class="connectbtn" onClick={() => window.location.href = '/about'}>ABOUT</button>
										<div class="discord"><img class="osPic" onClick={"#"} src={discord} /></div>
										<div class="discord"><img class="osPic" onClick={"#"} src={twitter} /></div>
									</div>

								</div>
							</div>
							<div class="boxWrap2">
								<div class="storyRight">
									<div class="storyH2">Le Louvre
									</div>

									<div class="vidCont2">
										<video class="videoBg" src={video2} autobuffer autoPlay loop muted playsInline></video></div>

									<div class="storyRight2-2">
										<div class="about1">
											<p class="storyCon"><span class="bigWord">H</span>ere is the place where all puzzle holders are able to place their puzzle to complete Full Pictures.</p>

										</div>
										<div class="about2">

										</div>
									</div>

									<div class="hoverPics"></div>

									<div class="storyRight2-2">
										<div class="about1">
											<p class="storyCon"><span class="bigWord">H</span>ere is the place where all eligible piece holders are able to mint full puzzle NFTs and middle piece NFTs</p>


										</div>

									</div>
								</div>

								{this.state.myNFTWallet > 0 ?
									(<div class="mintMain">
										<button class="mintBtns" onClick={this.mintPuzzle}>Puzzle NFTs</button>
										<button class="mintBtns" onClick={this.mintPiece}>Middle Piece NFTs</button>
									</div>) : null}

								<Element name="mint">
									<div class="nftblockWalletConnectedALL">

										{this.state.walletAddress === '' ? (

											<div class="walletConnect">
												<form onSubmit={this.wallet2Connect}>
													<button class="mintBtns" type='submit'>MINT NOW</button>
												</form>
											</div>
										) :

											(

												<div class="mintDiv">
													<div class="totalSupply">{this.state.totalSupply}/800</div>
													<div class="price"><div>Mint Price {this.state._cost / 1000000000000000000} ETH</div></div>

													{this.state._nickNameWithWalletCall !== "" || this.state._successfullyRegistered > 0 ?
														(<div class="mintbuttondiv">
															{this.state._mintStatus === true ? (
																<div>

																	{this.state._FinalResult === true ? (
																		<form onSubmit={this.whitelistMint}>
																			<button class="btnfos-0-3" type="submit">
																				Whitelist Mint</button>
																		</form>
																	) : (
																		<form onSubmit={this.whitelistMint}>
																			<button class="btnfos-0-3" type="submit">
																				{this.state._wlMsg}</button>
																		</form>)}
																</div>
															) : (<form onSubmit={this.onSubmit2}>
																<button class="btnfos-0-3" type="submit">
																	Mint Puzzle Pieces</button>
															</form>)}

														</div>) :
														(<div class="mintbuttondiv">
															{this.state._mintStatus === true ? (
																<div>

																	{this.state._FinalResult === true ? (
																		<form onSubmit={this.whitelistMint}>
																			<button class="btnfos-0-3" type="submit">
																				Whitelist Mint</button>
																		</form>
																	) : (
																		<form onSubmit={this.whitelistMint}>
																			<button class="btnfos-0-3" type="submit">
																				{this.state._wlMsg}</button>
																		</form>)}
																</div>
															) : (<form onSubmit={this.onSubmit2}>
																<button disabled class="btnfos-0-3-Dis" type="submit">
																	Mint Puzzle Pieces</button>
															</form>)}

															<div class="nicknameAndImg">

																<input type="text" class="nickName" name="yourName" placeholder="Submit your NickName" value={this.state._nickName}
																	onChange={event => this.setState({ _nickName: event.target.value })}>

																</input>
																<img onClick={this.setNickName} class="login" src={login} />

															</div>

														</div>)}

													<div>
														{this.state.statusError ? (
															<div class="errorMessage">
																<div >Sorry, something went wrong please try again later</div>
															</div>)
															: null}

														{this.state.statusLoading ? (
															<div class="loadingContainer">
																<div>
																	<div class="loadingText">Minting your Puzzle Piece</div>
																	<div class="loadTextSub">(this can take up to 30 seconds)</div>
																</div>
															</div>)
															: null}

														{this.state.success ? (

															<div><div class="successfully">MINTING SUCCESSFUL!</div>
															</div>)
															: null}

													</div></div>
											)}
									</div>
								</Element>

							</div>

						</div>) : null}

					{this.state._puzzleNFTS > 0 ?
						(<div class="boxWrap2Main">

							<div class="bidMain">
								<div class="bidMain2">
									<div class="bidT">Collect Puzzles</div>

									<div class="tokenIds" >
										{this.state._fullPuzzles.map((element) => {

											console.log("ID_Puzzle :" + element);

											//var ID = element1[0] + 1;

											if (true) {

												if (element == 0) {
													var url = "https://full-3ks.pages.dev/full/" + element + ".jpeg"; //"https://myfriendparrot.com/images/" + 6666 + ".png";
													console.log("url :" + url);


												} else {

													var url = "https://full-3ks.pages.dev/full/" + element + ".jpeg"; //"https://myfriendparrot.com/images/" + element1[0] + ".png";
													console.log("url :" + url);

												}

											} else {
												url = secretGif;
											}

											console.log("url :" + url);
											console.log("element1 :" + element);
											console.log("rewards1 :" + element);

											return (

												(<div class="tokenIds2">
													<div class="flip-card">
														<div class="flip-card-inner">
															<div class="flip-card-front">
																<div class="unStakeImg"><img class="images_show3" src={url} /></div>
															</div>

															<div class="flip-card-back">

																<div class="elementName2">Puzzle # {element}</div>
																<button class="stake3" onClick={() => this.collectScreen(element * 1)}>Collect</button>

															</div>
														</div>
													</div></div>));

										})}
									</div>

									<form onSubmit={this.closeWindow}>
										<button class="closeNote">✖</button>
									</form>
								</div>
							</div>

						</div>) : null}

					{this.state._minting > 0 ?
						(<div class="boxWrap2Main">

							<div class="bidMain">
								<div class="bidMain2">
									<div class="bidT">You can MINT</div>

									<div class="tokenIds" >
										{this.state._filteredMiddleList.map((element) => {

											console.log("ID :" + element);

											//var ID = element1[0] + 1;

											if (true) {

												if (element == 0) {
													var url = "https://middle100.pages.dev/5/" + element + ".png"; //"https://myfriendparrot.com/images/" + 6666 + ".png";
													console.log("url :" + url);


												} else {

													var url = "https://middle100.pages.dev/5/" + element + ".png"; //"https://myfriendparrot.com/images/" + element1[0] + ".png";
													console.log("url :" + url);

												}

											} else {
												url = secretGif;
											}

											console.log("url :" + url);
											console.log("element1 :" + element);
											console.log("rewards1 :" + element);

											return (

												(<div class="tokenIds2">
													<div class="flip-card">
														<div class="flip-card-inner">
															<div class="flip-card-front">
																<div class="unStakeImg"><img class="images_show" src={url} /></div>
															</div>

															<div class="flip-card-back">

																<div class="elementName2">Middle Piece # {element}</div>
																<button class="stake3" onClick={() => this.mintPieceM(element * 1)}>Mint</button>

															</div>
														</div>
													</div></div>));

										})}
									</div>

									<form onSubmit={this.closeWindow}>
										<button class="closeNote">✖</button>
									</form>
								</div>
							</div>

						</div>) : null}

					{this.state._collectScreen > 0 ?
						(<div class="boxWrap2Main">

							<div class="bidMain">
								<div class="bidMain2">
									<div class="bidT">Own your Puzzle</div>
									<div class="tokenIdsMintH">Pieces IDs</div>

									<div class="tokenIdsMint">

										{this.state._pieceID.map((element) => {

											console.log("ID :" + element);

											if (true) {

												if (element == 0) {
													var puzzlesPic = "https://normal800.pages.dev/800/" + element[1] + ".png"; //"https://myfriendparrot.com/images/" + 6666 + ".png";
													console.log("puzzlesPic :" + puzzlesPic);


												} else {

													var puzzlesPic = "https://normal800.pages.dev/800/" + element[1] + ".png"; //"https://myfriendparrot.com/images/" + element1[0] + ".png";
													console.log("puzzlesPic :" + puzzlesPic);

												}

											} else {
												puzzlesPic = secretGif;
											}

											console.log("puzzlesPic :" + puzzlesPic);
											console.log("element1 :" + element);
											console.log("rewards1 :" + element);

											finalMintingPieces[roundCount] = element[0];
											roundCount++;
											console.log("");
											//this.setState({ _finalMintingPieces: finalMintingPieces });

											return (

												(<div class="tokenIds2">
													<div class="flip-card">
														<div class="flip-card-inner">
															<div class="flip-card-front">
																<div class="unStakeImg"><img class="images_show2" src={puzzlesPic} /></div>
															</div>



															<div class="flip-card-back">

																<div class="elementName2">ID # {element[0]}</div>

															</div>
														</div>
													</div></div>));

										})}
									</div>

									<div class="tokenIdsMintH">Middle Piece</div>

									<div class="tokenIdsMint">

										<img class="piece" src={middlePieceIDUrl} />

									</div>
									{this.state._mintingAllowed2 === true ?
										(<button class="mintCollect" onClick={() => this.FinalMinting(

											finalMintingPieces[0],
											finalMintingPieces[1],
											finalMintingPieces[2],
											finalMintingPieces[3],
											finalMintingPieces[4],
											finalMintingPieces[5],
											finalMintingPieces[6],
											finalMintingPieces[7],
											this.state._middlePieceID

										)}>Mint</button>) :
										(<button class="mintCollectDis" disabled>Mint</button>)}

									<form onSubmit={this.closeWindow}>
										<button class="closeNote">✖</button>
									</form>
								</div>
							</div>

						</div>) : null}

					{this.state._mintDialogue > 0 ?

						(<div class="noteMain">
							<div><div class="notOwner">You are not the Bid Winner</div></div>
							<form onSubmit={this.closeWindow}>
								<button class="closeNote">✖</button>
							</form>
						</div>

						) : null

					}

					{this.state._biddingInfo > 0 ?
						(<div class="boxWrap2Main">
							<div class="boxWrap2">
								<img class="bidImg" src={bid} />
								<div class="bidSuccess">Bid Successfully Placed!</div>
								<button class="back2" onClick={() => this.biddingP(this.state._selectedPuzzleID, this.state._selectedTokenID)}>Bidding Area</button>
							</div>
						</div>) : null}


					<div class="footer">
						<div class="footer2">
							<div class="copyright">"It is always fatal..."</div>
						</div>
					</div>

				</div>

			</div >)
	}
}

export default Home;
